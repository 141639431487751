<template>
    <div ref="container">
        <div class="expandable-card__text">
            <h3 class="t-h5">
                {{ title }}
            </h3>

            <!-- eslint-disable vue/no-v-html -->
            <p
                ref="subtitleRef"
                class="expandable-card__subtitle"
                :class="{ 'expandable-card__subtitle--limit': limitHeight }"
                v-html="formattedSubtitle"
            ></p>
            <!-- eslint-disable vue/no-v-html -->

            <a
                v-if="limitHeight"
                class="expandable-card__read-more-btn t-link-arrow t-link-arrow--black t-link-arrow--bold t-link-arrow--black-hover"
                @click="handleClick"
            >
                {{ t("Read more") }}
            </a>

            <a
                v-if="limitHeight"
                class="expandable-card__read-less-btn t-link-arrow t-link-arrow--black t-link-arrow--bold t-link-arrow--black-hover"
                @click="handleClick"
            >
                {{ t("Read less") }}
            </a>

            <p class="expandable-card__number">
                {{ number }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { t } from "../../filters";
import { ref, computed, onMounted } from "vue";

// Props
const props = defineProps({
    title: {
        type: String,
        default: "",
    },
    subtitle: {
        type: String,
        default: "",
    },
    number: {
        type: String,
        default: "",
    },
    useMouseTrigger: {
        type: Boolean,
        default: false,
    },
    targetElement: {
        type: String,
        default: "strong",
    },
});

// Refs
const container = ref(null);
const subtitleRef = ref(null);
const limitHeight = ref(false);
const isExpanded = ref(false);

// Computed properties
const formattedSubtitle = computed(() => {
    return props.subtitle.replace(/\n/g, "<br>");
});

const handleClick = () => {
    const parentEl = container.value.parentElement;
    const expandedElements = document.querySelectorAll(
        ".expandable-card--expanded"
    );

    if (parentEl.classList.contains("expandable-card--expanded")) {
        parentEl.classList.remove("expandable-card--expanded");
        isExpanded.value = false;
    } else {
        expandedElements.forEach((el) => {
            el.classList.remove("expandable-card--expanded");
        });

        parentEl.classList.add("expandable-card--expanded");
        isExpanded.value = true;
    }
};

// Calc subtitle lines
const calculateLines = () => {
    if (subtitleRef.value) {
        const subtitleHeight = subtitleRef.value.offsetHeight;
        const lineHeight = parseInt(
            window.getComputedStyle(subtitleRef.value).lineHeight,
            10
        );
        const numberOfLines = Math.ceil(subtitleHeight / lineHeight);

        if (numberOfLines > 5) {
            limitHeight.value = true;
        } else {
            limitHeight.value = false;
        }
    }
};

onMounted(() => {
    calculateLines();
});
</script>
