import "core-js/stable";
import "regenerator-runtime/runtime";
import "./styles/main.scss";
import "./modules";
import "./components";
import intlTelInput from "intl-tel-input";
import formToDataLayer from "./modules/formToDataLayer.js";
import { Swiper } from "swiper";
import { Navigation } from "swiper/modules";

formToDataLayer();

window.intlTelInput = intlTelInput;

window.Swiper = Swiper;
window.SwiperNavigation = Navigation;

(async () => {
    if (!("scrollBehavior" in document.documentElement.style)) {
        await import("scroll-behavior-polyfill");
    }
})();
